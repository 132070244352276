@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
.navBar {
  overflow: hidden;
  height: 70px;
  display: flex;
  vertical-align: middle;
  position: fixed;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.108);
  width: 100vw;
  z-index: 100;
}
#homeHeader {
  padding-left: 30px;
}

.navBar p {
  float: left;
  color: #000000;
  text-align: right;
  padding: 27px 20px;
  text-decoration: none;
  font-size: 16px;
  vertical-align: middle;
  justify-content: space-between;
}
.navBar a {
  float: left;
  color: #000000;
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding: 27px 20px;
  text-decoration: none;
  font-size: 16px;
  vertical-align: middle;
  justify-content: space-between;
}
#buttonQuote {
  margin-top: 20px;
  position: fixed;
  left: 88vw;
  color: white;
  font-size: 14px;
  z-index: 1;
  font-family: "Poppins";
  text-transform: capitalize;
  background-color: #131835;
}
#buttonQuote:hover {
  background-color: #2b3153;
}
.navBar p:hover {
  background-color: rgba(132, 132, 132, 0.5);
  color: rgb(255, 255, 255);
}
.navBar a:hover {
  background-color: rgba(132, 132, 132, 0.5);
  color: rgb(255, 255, 255);
}
.navBar p.active {
  text-decoration: underline;
  text-decoration-thickness: 2.1px;
  text-underline-offset: 5px;
  color: rgb(0, 0, 0);
  padding-left: 20px;
}
.navBar p.active:hover {
  color: rgb(255, 255, 255);
}
#modalDiv {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 400px;
  background-color: white;
  width: 32%;
  border-radius: 6px;
}

#logo {
  padding: 40px;
  text-align: end;
  float: right;
  right: 0px;
  align-items: flex-end;
  justify-content: right;
  padding-top: 26px;
}
@media screen and (max-width: 850px) {
  #buttonQuote {
    left: 80vw;
  }
  #homeHeader {
    padding-left: 20px;
  }
}
@media screen and (max-width: 600px) {
  .navBar a {
    padding: 27px 8px;
  }
  #buttonQuote {
    left: 70vw;
    font-size: 11px;
  }
}
@media screen and (max-width: 400px) {
  #buttonQuote {
    left: 64vw;
    font-size: 9px;
  }
}
