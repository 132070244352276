@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  padding: 0;
  margin: 0;
}

#container {
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  height: 100vh;
  background-color: white;
  justify-content: center;
}
#box {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px 200px 0px 200px;
}

.type {
  display: block;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 12px;
}
#contactTitle {
  text-align: center;
  color: #131835;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 26px;
  padding-top: 140px;
  margin-top: 0px;
  padding-bottom: 30px;
}
#name {
  width: 22vw;
  height: 40px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  outline: none;
  padding-left: 10px;
  background-color: rgb(243, 243, 243);
}
#info {
  width: 22vw;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  border: none;
  outline: none;
  padding-left: 10px;
  background-color: rgb(243, 243, 243);
}
#number {
  width: 22vw;
  height: 40px;
  border-radius: 4px;
  padding-left: 10px;
  font-size: 14px;
  border: none;
  outline: none;
  background-color: rgb(243, 243, 243);
}
#email {
  width: 22vw;
  height: 40px;
  border-radius: 4px;
  border: none;
  outline: none;
  padding-left: 10px;
  font-size: 14px;
  background-color: rgb(243, 243, 243);
}
.big {
  display: block;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 12px;
  font-size: 14px;
  width: 22vw;
  padding-bottom: 10px;
}

#submit {
  color: white;
  background-color: #131835;
  width: 180px;
  height: 45px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  border-style: none;
  margin-top: 10px;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
}
#submit:hover {
  background-color: rgb(216, 216, 216);
}
#bt {
  display: flex;
  justify-content: center;
}
#box div {
  width: 30vw;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 850px) {
  #container {
    height: 75vh;
  }
  #box {
    margin: 20px 20px 0px 20px;
  }
}
@media only screen and (max-width: 600px) {
  #box div {
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
  }
  #container {
    height: 75vh;
  }
}
