@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
#projectPage {
  width: 60vw;
  margin: auto;
  width: 48%;
}
.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 430px;
  width: 100%;
}
#title {
  text-align: center;
  font-size: 30px;
  color: #131835;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  padding: 50px 0px;
}
#slide2 {
  margin: 140px 0px;
}
@media only screen and (max-width: 850px) {
  .each-slide-effect > div {
    height: 260px;
  }
}
@media only screen and (max-width: 600px) {
  #projectPage {
    width: 100%;
    margin-top: 7vh;
  }
}
