@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

#thirdPart {
  background-color: charcoal;
  border-top: 1px solid white;
  padding: 30px 0px;
}
#contact p {
  display: block;
  color: #131835;
  padding-bottom: 10px;
  text-align: center;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  font-size: 14px;
}
#contact a {
  color: #131835;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  text-decoration: underline;
}
