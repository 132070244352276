@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
#firstPage {
  background-image: url("../../assets//image13.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 50vw;
  height: 70vh;
  background-color: #fdfdfd;
  background-position-y: center;
  background-position: center;
  margin-bottom: 15vh;
}
#landingPage {
  font-family: "Poppins", sans-serif;
  padding-top: 10vh;
  height: 100vh;
}

#header {
  font-size: 36px;
  color: #ffffff;
  padding: 100px 0px 0px 0px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
#imgDiv {
  background-color: #1b2028;
  padding-left: 50px;
  left: 100%;
  height: 100%;
  margin-top: 6vh;
  padding-right: 80px;
  position: relative;
}
#imgDiv p {
  font-size: 14px;
  color: #000000;
  color: white;
  padding-bottom: 25px;
  text-align: start;
  font-weight: 400;
}
#buttonHome {
  color: #1b2028;
  font-family: "Poppins", sans-serif;

  font-weight: 500;
  background-color: white;
}
p {
  font-family: "Poppins";
}
a {
  color: #4d3e41;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}
#buttonHome:hover {
  background-color: rgb(216, 216, 216);
}
@media only screen and (max-width: 850px) {
  #imgDiv {
    left: 0%;
    width: 100vw;
    top: 34vh;
  }
  #firstPage {
    height: 35vh;
    width: 100vw;
  }
  #landingPage {
    padding-top: 10vh;
  }
}
@media only screen and (max-width: 600px) {
  #landingPage {
    padding-top: 6vh;
  }
  #imgDiv {
    top: 44vh;
    height: 70%;
  }
}
@media only screen and (max-width: 400px) {
  #landingPage {
    padding-top: 5vh;
  }
  #firstPage {
    margin: 0px 0px;
  }
}
