@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

#servicesDiv {
  height: 100vh;
}
#servDiv {
  display: flex;
  justify-content: space-evenly;
}
#servDiv div {
  background-color: #131835;
  height: 70vh;
  width: 30vw;
  margin-top: 12vh;
}
#servDiv div h1 {
  color: white;
  font-size: 20px;
  padding: 4vh 3vw 2vh 3vw;
  font-weight: 600;
}
#servDiv div p {
  color: white;
  padding: 0vh 3vw 0.5vh 3vw;
  margin: 0px;
  font-size: 14px;
  line-height: 20px;
}
#servDiv div img {
  width: 100%;
  height: 18vw;
  margin-bottom: 0;
  padding-bottom: 0px;
}
#customId {
  margin-top: 7vh;
}
#apartmentImg {
  margin-top: 10vh;
}
#renImg {
  margin-top: 2vh;
}
#modalInputs {
  margin: auto;
  display: block;
  width: 70%;
}
#modalDiv {
  margin: auto;
  padding-top: 30px;
  width: 30vw;
  display: block;
  text-align: center;
}
#submitQuote {
  color: white;
  background-color: #131835;
  width: 100px;
  height: 35px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  border-style: none;
  margin: auto;
  text-transform: uppercase;
}
@media only screen and (max-width: 850px) {
  #servDiv {
    display: inline;
  }
  #servDiv div {
    height: 26vh;
    width: 100vw;
    margin-top: 0px;
  }
  #servDiv div img {
    width: 40vw;
    float: right;
    margin-top: -14vh;
  }
  #servDiv div p {
    width: 53vw;
  }
  #modalDiv {
    width: 46vw;
  }
  #servicesDiv {
    padding-top: 15vh;
  }
}
@media only screen and (max-width: 600px) {
  #modalDiv {
    width: 78vw;
  }
  #servicesDiv {
  margin-top: 0vh;
  }
  #servDiv div p {
    padding: 0vh 3vw 0.5vh 3vw;
    font-size: 11px;
    line-height: 14px;
  }
  #servDiv div h1 {
    font-size: 15px;
  }
  #servDiv div {
    height: 27vh;
  }
  #servDiv div img {
    width: 48vw;
    float: right;
    height: 28vw;
    margin-top: -15vh;
  }
}
@media only screen and (max-width: 380px) {
  #servDiv div img {
    margin-top: -20vh;
  } #servDiv div {
    height: 32vh;
  }
}
